(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			BlogMetro: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var $container = $( '.blog-metro' ).isotope( {
						itemSelector: '.blog-metro-item',
						percentPosition: true,
						masonry: {
							isFitWidth: true,
							columnWidth: '.item-sizer'
						}
					} );
					setTimeout(function(){
						$container.isotope( 'layout' );
					}, 100);
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
