function insight_header_side() {
	var $body = jQuery( 'body' );
	if ( ! $body.hasClass( 'has-header-07' ) ) {
		return;
	}
	var $contentWidth = jQuery( '#page' ).width();
	var $boxWidth = jQuery( '#primary' ).width();
	var $spacing = (
		               $contentWidth - $boxWidth
	               ) / 2;
	var rows = jQuery( '#main .entry-content' ).children( '.vc_row' );
	rows.each( function() {
		if ( jQuery( this ).attr( 'data-vc-full-width' ) ) {
			jQuery( this ).css( {
				left: - $spacing + 'px',
				width: $contentWidth + 'px'
			} );
			if ( ! jQuery( this ).attr( 'data-vc-stretch-content' ) ) {
				jQuery( this ).css( {
					paddingLeft: $spacing,
					paddingRight: $spacing
				} );
			}
		}
	} );
}

(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			HeaderSide: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					insight_header_side();

					jQuery( window ).on( 'resize', function() {
						insight_header_side();
					} );
				}
			}
		} );
	}
).apply( this, [window.insight, jQuery] );
