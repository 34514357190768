(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			SearchBoxMobile: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var topSearch = $( '.top-search-mobile' );
						$( '.open-search-mobile' ).on( 'click', function() {
							if ( ! topSearch.hasClass( 'open' ) ) {
								topSearch.addClass( 'open' );
								topSearch.slideDown();
								$( '.top-search-mobile .search-field' ).focus();
							} else {
								topSearch.slideUp();
								topSearch.removeClass( 'open' );
							}
						} );
						$( document ).on( 'click', function( e ) {
							if ( (
								     $( e.target ).closest( topSearch ).length == 0
							     ) && (
								     $( e.target ).closest( '.open-search-mobile' ).length == 0
							     ) ) {
								if ( topSearch.hasClass( 'open' ) ) {
									topSearch.slideUp();
									topSearch.removeClass( 'open' );
								}
							}
						} );
				},
			}
		} );
	}
).apply( this, [window.insight, jQuery] );
