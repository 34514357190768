(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			MiniCart: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var $mini_cart = $('.mini-cart');
					$mini_cart.on('click', function(e) {
				        $(this).closest('.mini-cart-wrap').addClass('open');
				    });
					$('.mini-cart-wrap').on('click', function(e) {
				        $(this).addClass('open');
				    });

					$(document).on('click', function(e) {
						if ( (
								 $( e.target ).closest( '.mini-cart-wrap' ).length == 0
							 ) && (
								 $( e.target ).closest( '.mini-cart' ).length == 0
							 ) ) {
							if ( $('.mini-cart-wrap').hasClass( 'open' ) ) {
								$('.mini-cart-wrap').removeClass('open');
							}
						}
				    });
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
