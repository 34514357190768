(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			MobileMenu: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {

					$('.open-menu-mobile').on('click', function(e) {
						if($(this).hasClass('active')) {
							$('#mobile').removeClass('active');
							$(this).removeClass('active');
							$(this).find('i').addClass('fa-bars').removeClass('fa-times');
						} else {
							$('#mobile').addClass('active');
							$(this).addClass('active');
							$(this).find('i').removeClass('fa-bars').addClass('fa-times');
						}
				    });

					// Show sub-menu
					var $menu = $( '#mobile' );

					$( '#mobile .sub-menu-toggle' ).on( 'click', function( e ) {
						var subMenu = $( this ).next();

						if ( subMenu.css( 'display' ) == 'block' ) {
							subMenu.css( 'display', 'block' ).slideUp().parent().removeClass( 'expand' );
						} else {
							subMenu.css( 'display', 'none' ).slideDown().parent().addClass( 'expand' );
						}
						e.stopPropagation();
					} );

					$( document ).on( 'click', function( e ) {
						if ( (
								 $( e.target ).closest( '#mobile' ).length == 0
							 ) && (
								 $( e.target ).closest('.open-menu-mobile').length == 0
							 ) ) {

 								$('#mobile').removeClass('active');
 								$('.open-menu-mobile').removeClass('active');
 								$('.open-menu-mobile').find('i').addClass('fa-bars').removeClass('fa-times');
						}
					} );

				},
			}
		} );
	}
).apply( this, [window.insight, jQuery] );
