(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Woo: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.woo-single-images, .woo-single-summary' ).stick_in_parent();
					jQuery( 'body.single-product .woo-single-images' ).lightGallery( {
						selector: 'a',
						thumbnail: true,
						animateThumb: false,
						showThumbByDefault: false
					} );

					// product carousel
					$( '.insight-product-carousel' ).each( function() {
						var this_carousel = jQuery( this ).find( '.woo-products' );
						var data_slick = jQuery( this ).data( 'carousel-settings' );
						var responsive = {
							responsive: [
								{
									breakpoint: 768,
									settings: {
										slidesToShow: 1,
										slidesToScroll: 1
									}
								}
							]
						};

						var settings = $.extend( {}, data_slick, responsive );

						this_carousel.slick( settings );
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
