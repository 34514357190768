(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			SearchBox: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var topSearch = $( '.top-search' );
					$( '.top-search-btn' ).on( 'click', function() {
						if ( ! topSearch.hasClass( 'open' ) ) {
							topSearch.addClass( 'open' );
							topSearch.slideDown();
							topSearch.find( '.search-field' ).focus();
						} else {
							topSearch.slideUp();
							topSearch.removeClass( 'open' );
						}
					} );
					$( document ).on( 'click', function( e ) {
						if ( (
							     $( e.target ).closest( topSearch ).length == 0
						     ) && (
							     $( e.target ).closest( '.top-search-btn' ).length == 0
						     ) ) {
							if ( topSearch.hasClass( 'open' ) ) {
								topSearch.slideUp();
								topSearch.removeClass( 'open' );
							}
						}
					} );
				},
			}
		} );
	}
).apply( this, [window.insight, jQuery] );
