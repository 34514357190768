(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Tabs: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					$.fn.insightTabs = function() {
						var thisTabs = this;
						thisTabs.find( '.nav-tab .item' ).on( 'click', function() {
							$( this ).addClass( 'active' ).siblings().removeClass( 'active' );
							$( $( this ).data( 'tab-target' ) ).addClass( 'active' ).siblings().removeClass( 'active' );
						} );
					};
					$( '.insight-tabs' ).each( function() {
						$( this ).insightTabs();
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
