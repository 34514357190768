(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Carousel: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					$( '[data-insight-carousel="true"]' ).each( function() {
						var data_slick = jQuery( this ).data( 'carousel-settings' );
						var responsive = {
							responsive: [
								{
									breakpoint: 768,
									settings: {
										slidesToShow: 1,
										slidesToScroll: 1
									}
								}
							]
						};

						var settings = $.extend( {}, data_slick, responsive );

						jQuery( this ).slick( settings );
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
