(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			RowInner: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '[data-ac="true"]' ).each( function() {
						if ( jQuery( this ).attr( 'data-mw' ) ) {
							var max_width = jQuery( this ).data( 'mw' );
							jQuery( this ).css( 'max-width', max_width );
						}
						if ( jQuery( this ).attr( 'data-al' ) ) {
							var align = jQuery( this ).data( 'al' );
							if ( align == 'left' ) {
								jQuery( this ).css( 'margin-left', 0 );
							}
							if ( align == 'center' ) {
								jQuery( this ).css( 'margin-left', 'auto' );
								jQuery( this ).css( 'margin-right', 'auto' );
							}
							if ( align == 'right' ) {
								jQuery( this ).css( 'margin-left', 'auto' );
								jQuery( this ).css( 'margin-right', 0 );
							}
						}
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
