(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Countdown: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					// Countdown
					jQuery( '.insight-countdown' ).each( function() {
						var data_id = jQuery( this ).data( 'id' );
						var target = new Date( jQuery( '#' + data_id ).text() );
						var current = new Date();
						if ( target.getTime() < current.getTime() ) {
							document.getElementById( data_id ).innerHTML = "";
							return;
						}

						countdown.resetLabels();
						countdown.setLabels(
							' millisecond| <span>second</span>| <span>minute</span>| <span>hour</span>| <span>day</span>| <span>week</span>| <span>month</span>| <span>year</span>| <span>decade</span>| <span>century</span>| <span>millennium</span>',
							' milliseconds| <span>seconds</span>| <span>minutes</span>| <span>hours</span>| <span>days</span>| <span>weeks</span>| <span>months</span>| <span>years</span>| <span>decades</span>| <span>centuries</span>| <span>millennia</span>',
							'',
							'',
							'',
							function( n ) {
								if ( n < 10 ) {
									return '0' + n.toString();
								}
								return n.toString();
							} );
						countdown(
							target,
							function( ts ) {
								if ( ts.hours === 0 ) {
									ts.hours = '0';
								}
								if ( ts.minutes === 0 ) {
									ts.minutes = '0';
								}
								if ( ts.seconds === 0 ) {
									ts.seconds = '0';
								}
								if ( ts.days === 0 ) {
									ts.days = '0';
								}
								document.getElementById( data_id ).innerHTML = ts.toHTML( 'div' );
							},
							countdown.DAYS + countdown.HOURS + countdown.MINUTES + countdown.SECONDS
						);
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
