(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			PopupVideo: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.video_btn' ).magnificPopup( {
						type: 'iframe'
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
