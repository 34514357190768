(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			BlogMasonry: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var $container = $( '.blog-masonry' ).isotope( {
						itemSelector: '.blog-masonry-item',
						percentPosition: true,
						masonry: {
							isFitWidth: true,
							columnWidth: '.item-sizer'
						}
					} );

					setTimeout(function(){
						$container.isotope( 'layout' );
					}, 100);
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
