(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Separator: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.insight-separator-btn' ).on( 'click', function( e ) {
						jQuery( 'html, body' ).animate( {scrollTop: 0}, 600 );
						e.preventDefault();
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
